<template>
  <div v-if="$root.user && loaded">
    <div v-if="patronTier < 2" class="row mb-3">
      <div class="col-12">
        <b-card>
          <Markdown
            :markdown="
              $t('myBoostedServers.youCannotBoostServers', { patreonLink })
            "
          />
        </b-card>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-12">
          <b-card>
            <Markdown
              :markdown="
                $t('myBoostedServers.boostedServers', {
                  patronTier,
                  boostsLeft,
                })
              "
            />
            <div>
              <b-button
                class="mr-3"
                v-b-modal.guild-select-modal
                variant="info"
                :disabled="boostedGuilds.length >= boostCount"
                ><i class="mr-2 fa fa-plus"></i
                >{{ $t("myBoostedServers.addServer") }}</b-button
              >
            </div>
          </b-card>
        </div>
      </div>

      <div class="row" v-if="boostedGuilds && boostedGuilds.length">
        <div
          class="mb-3 col-lg-6"
          v-for="(guild, index) in boostedGuilds"
          :key="index"
        >
          <b-card
            :title="guild.name"
            :img-src="guild.iconUrl"
            :img-alt="guild.name"
            img-left
            class="mb-2"
          >
            <b-button
              @click="removeBoostedGuildAsync(guild)"
              size="sm"
              variant="secondary"
            >
              <i class="fa fa-minus"></i
              ><span class="ml-2 d-none d-sm-inline">{{
                $t("myBoostedServers.remove")
              }}</span>
            </b-button>
          </b-card>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-md-12">
          <b-card>
            <b-alert variant="warning" show
              ><i class="mr-2 fa fa-exclamation-triangle"></i
              >{{ $t("myBoostedServers.noServersBoosted") }}</b-alert
            >
          </b-card>
        </div>
      </div>

      <b-modal
        id="guild-select-modal"
        size="xl"
        :title="$t('myBoostedServers.pickServersToBoost')"
        ok-title="Cancel"
        :ok-only="true"
      >
        <template #default="{ hide }">
          <div class="row">
            <div class="col-md-12">
              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  :placeholder="$t('myBoostedServers.searchForServer')"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>

          <div class="row" v-if="filteredGuilds.length">
            <div
              class="mb-3 col-lg-6"
              v-for="(guild, index) in filteredGuilds"
              :key="index"
            >
              <b-card
                :title="guild.name"
                :img-src="guild.iconUrl"
                :img-alt="guild.name"
                img-left
                class="mb-2"
              >
                <b-button
                  @click="
                    addBoostedGuildAsync(guild);
                    hide();
                  "
                  v-if="boostedGuilds.indexOf(guild) === -1"
                  :disabled="boostedGuilds.length >= boostCount"
                  variant="info"
                >
                  <i class="fa fa-plus"></i
                  ><span class="ml-2 d-none d-sm-inline">{{
                    $t("myBoostedServers.addToBoosted")
                  }}</span>
                </b-button>
                <span v-else class="text-muted">{{
                  $t("myBoostedServers.alreadyAdded")
                }}</span>
              </b-card>
            </div>
          </div>
          <div v-else>{{ $t("myBoostedServers.noServersFound") }}</div>
        </template>
      </b-modal>
    </div>
  </div>
  <Loading v-else />
</template>

<script>
import Loading from "../components/Loading";
import Markdown from "../components/Markdown.vue";

export default {
  components: {
    Loading,
    Markdown,
  },

  props: {},
  computed: {
    filteredGuilds: function() {
      var unboostedGuilds = this.userGuilds.filter(
        (g) => this.boostedGuilds.map((bg) => bg.id).indexOf(g.id) === -1
      );
      if (!this.search) return unboostedGuilds;
      var self = this;
      return unboostedGuilds.filter(function(g) {
        return g.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
    },
    boostsLeft: function() {
      return this.boostCount - this.boostedGuilds.length;
    },
  },

  methods: {
    async removeBoostedGuildAsync(guild) {
      this.boostedGuilds.splice(this.boostedGuilds.indexOf(guild), 1);
      this.boostedCount = this.boostedGuilds.length;
      await this.updateBoostedGuildsAsync();
    },

    async addBoostedGuildAsync(guild) {
      if (this.boostedGuilds.length >= this.boostCount) return;
      if (this.boostedGuilds.map((g) => g.id).indexOf(guild.id) > -1) return;
      this.boostedGuilds.push(guild);
      this.boostedCount = this.boostedGuilds.length;
      await this.updateBoostedGuildsAsync();
    },

    async updateBoostedGuildsAsync() {
      try {
        var request = {
          boostedGuilds: this.boostedGuilds.map((g) => {
            return {
              id: g.id,
            };
          }),
        };
        var response = await this.axios.post(
          "/api/user/boosted-guilds",
          request
        );

        if (response.status === 200) {
          this.$bvToast.toast(this.$t("myBoostedServers.savedChanges"), {
            title: "Success",
            autoHideDelay: 3000,
            variant: "success",
          });
        } else {
          this.$bvToast.toast(this.$t("myBoostedServers.couldNotSaveChanges"), {
            title: "Error",
            autoHideDelay: 3000,
            variant: "danger",
          });
        }
      } catch {
        this.$bvToast.toast(this.$t("myBoostedServers.couldNotSaveChanges"), {
          title: "Error",
          autoHideDelay: 3000,
          variant: "danger",
        });
      }
    },
  },

  data() {
    return {
      busy: false,
      boostedGuilds: [],
      boostCount: 0,
      boostedCount: 0,
      userGuilds: [],
      loaded: false,
      search: null,
      patronTier: 0,
      patreonLink: this.$root.config.patreonUrl,
    };
  },

  async created() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    this.busy = true;
    try {
      var userDetailsResponse = await this.axios.get("/api/user/details");
      this.patronTier = userDetailsResponse.data.patronTier;
      if (this.patronTier > 3) this.boostCount = 99;
      else if (this.patronTier > 2) this.boostCount = 3;
      else if (this.patronTier > 1) this.boostCount = 1;

      var boostedGuildsResponse = await this.axios.get(
        "/api/user/boosted-guilds"
      );
      this.boostedGuilds.push(...boostedGuildsResponse.data);
      this.boostedCount = this.boostedGuilds.length;

      var guildsResponse = await this.axios.get("/api/user/guilds");
      this.userGuilds.push(...guildsResponse.data);

      this.busy = false;
      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style scoped>
.card-body {
  width: calc(100% - 128px);
}

.card-img-left {
  width: 128px;
  height: 128px;
}
</style>
